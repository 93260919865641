export const UserRole = {
  User: 1,
  Provider: 2,
  CareNavigator: 3,
  Admin: 4,
  Billing: 5,
  Insurer: 6,
  SuperAdmin: 7,
};

export const exerciseDurationType = {
  reps: 1,
  hold: 2,
  repsAndHold: 3,
};

export const defaultMenuType = 'menu-default';
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
];

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const allowedUploadFormats = [
  'xlsx',
  'xls',
  'txt',
  'csv',
  'pdf',
  'doc',
  'docx',
  'ods',
  'webm',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'ogg',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'mp3',
  'wav',
];

export const sortColumns = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  DATE_OF_BIRTH: 'dob',
  DATE_ENROLLED: 'created_at',
  ORGANIZATION: 'organization',
  GROUP: 'clinic',
  CHAT: 'chat',
};

export const sortOrders = {
  1: 'ASC',
  '-1': 'DESC',
};

export const months = [
  null,
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
