import { getRequest, postRequest, deleteRequest } from './base';

export const changeUserPassword = (id, password) => {
  return postRequest('user/setPassword', {
    id,
    password,
  }).then((response) => response.data);
};

export const getUserFiltersView = async (section) => {
  const response = await getRequest(`/filters/list?section=${section}`);

  return response.data;
};

export const createUserFiltersView = async (section, filters, name) => {
  const filterWithValues = Object.fromEntries(Object.entries(filters).filter(([_,value]) => value));
  const response = await postRequest(`/filters`, {
    section,
    filters: filterWithValues,
    name,
  });

  return response.data;
};

export const deleteUserFiltersView = async (id) => {
  const response = await deleteRequest(`/filters/delete/${id}`);

  return response.data;
};
