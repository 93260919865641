import { put, call, select } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import {
  createHepProgram,
  getProgramById,
  getTemplates,
} from 'services/api/program';
import {
  prepareBuilderDataToSave,
  prepareExercisesToSave,
} from 'helpers/programHelper';
import { getProgramPDF, downloadPDF } from 'services/api/pdf';
import { editParticipant } from 'services/api/participant';
import printJS from 'print-js';
import {
  HepTypes as Types,
  HepActions as Actions,
  HepSelectors as Selectors,
} from './hepReducer';
import { HepDrawerSelectors, HepDrawerActions } from './drawerReducer'


function* fetchHepTemplates(action) {
  const { search } = action;

  yield put(Actions.setHepTemplatesIsLoading(true));
  try {
    const response = yield call(getTemplates, search || '');
    yield put(Actions.fetchHepTemplatesSuccess(response));
  } catch (error) {
    yield put(Actions.fetchHepTemplatesSuccess([]));
    console.log(error);
  } finally {
    yield put(Actions.setHepTemplatesIsLoading(false));
  }
}

function* fetchProgramPrint(action) {
  const {
    participantId,
    layout,
    mode,
    participantLastName,
    programName,
    caseId,
    isLoginRestricted,
  } = action;

  try {
    yield put(Actions.setProgramPrintIsLoading(true));
    const { data } = yield call(
      getProgramPDF,
      participantId,
      layout,
      caseId,
      isLoginRestricted
    );

    if (!data.file) {
      throw new Error('not file found on the data');
    }

    yield put(Actions.fetchProgramPrintSuccess(data.file));

    switch (mode) {
      case 'NATIVE_PRINT':
        printJS({ printable: data.file, type: 'pdf', showModal: true });
        break;
      case 'DOWNLOAD':
        yield call(downloadPDF, data.file, participantLastName, programName);
        break;
      default:
        throw new Error('unhandle error');
    }
  } catch (error) {
    const data = {
      name: 'PRINT_ERROR',
      message: 'Something went wrong printing the PDF',
    };
    yield put(Actions.setErrors([data]));
  } finally {
    yield put(Actions.setProgramPrintIsLoading(false));
  }
}

function* createHep(action) {
  const { data } = action;
  const {
    isTemplate,
    participantId,
    editId,
    name,
    exercises,
    isEditingTemplate,
    hepAction,
    participantToEdit,
    caseId,
  } = data;

  const builderData = yield select(Selectors.getBuilderData);

  try {
    yield put(Actions.setCreateHepIsLoading(true));

    if (participantToEdit.edit) {
      yield call(editParticipant, participantToEdit);
    }

    const formatedBuilderData = prepareBuilderDataToSave(builderData);
    const formatExercises = prepareExercisesToSave(exercises);

    const body = {
      exercises: formatExercises,
      builderData: JSON.stringify(formatedBuilderData),
      template: isTemplate,
      participantId,
      editId,
      isEditingTemplate,
      name,
      caseId,
    };

    yield call(createHepProgram, body);

    let message = 'Program successfully created and assigned to participant.';
    if (isTemplate) {
      message = 'Template successfully created.';
      yield put(Actions.resetHep());
    }

    Swal.fire({
      text: `Program successfully assigned`,
      icon: 'success',
      showCancelButton: false,
      allowOutsideClick: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500
    });

    const shouldPrint = yield select(HepDrawerSelectors.getShouldPrint);
    if (shouldPrint) {
      yield put(HepDrawerActions.setIsPrintOpen(true));
    }

    yield put(Actions.setBuilderData([]));
    yield put(Actions.createHepSuccess(hepAction));
    yield put(Actions.resetHepToEdit());
  } catch (e) {
    Swal.fire({
      title: 'Error',
      text: 'Something went wrong assigning program!',
      icon: 'error',
      showCancelButton: false,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      confirmButtonText: 'Continue',
    });

    const error = {
      name: 'CREATE_HEP_ERROR',
      message: 'Something went wrong Creating hep',
      error: e,
    };
    yield put(Actions.setErrors([error]));
  } finally {
    yield put(Actions.setCreateHepIsLoading(false));
  }
}

function* getHepToEdit(action) {
  const { programId, editable } = action;
  yield put(Actions.setHepToEditIsLoading(true));

  try {
    const result = yield call(getProgramById, programId, false, editable);

    const { builder_data: builderData, name, id } = result;

    yield put(Actions.setBuilderData(JSON.parse(builderData)));
    yield put(Actions.getHepToEditSuccess({ id, name }));
  } catch (e) {
    console.log('error', e);
    const error = {
      name: 'CREATE_HEP_ERROR',
      message: 'Something went wrong getting hep to edit',
      error: e,
    };
    yield put(Actions.setErrors([error]));
  } finally {
    yield put(Actions.setHepToEditIsLoading(false));
  }
}

export default () => ({
  [Types.FETCH_HEP_TEMPLATES]: fetchHepTemplates,
  [Types.FETCH_PROGRAM_PRINT]: fetchProgramPrint,
  [Types.CREATE_HEP]: createHep,
  [Types.GET_HEP_TO_EDIT]: getHepToEdit,
});
