import Immutable from 'seamless-immutable';

export const caseStatus = Immutable({
    ACTIVE: 'ACTIVE',
    PLANNED: 'PLANNED',
    ONHOLD: 'ONHOLD',
    FINISHED: 'FINISHED',
});

export const serviceTypeIds = Immutable({
    RTM: 5,
    HEP_ONLY: 4,
    VBC: 7,
    SCREENING: 17
});

export const IS_OPEN_CREATE_CONSTANT = 'create';
