import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { filters as filtersKeys } from 'constants/participantsFilters';

const { Types, Creators } = createActions(
  {
    fetchParticipants: ['filters', 'search'],
    fetchParticipantsSuccess: ['participantsList', 'prevParams'],
    setNavigation: ['navigation'],
    setParticipantsErrors: ['error'],
    setParticipantsLoading: ['isloadingParticipant'],
    fetchFilters: [],
    fetchFiltersSuccess: ['filters'],
    fetchFields: [],
    fetchFieldsSuccess: ['fields'],
    fetchProviders: ['groupIds', 'orgIds', 'from'],
    setFiltersLoading: ['isloadingFilters'],
    setFieldsLoading: ['isloadingFields'],
    setSelectedFilters: ['filters', 'search', 'from'],
    setSelectedFiltersSuccess: ['filters', 'search'],
    setOrdering: ['ordering'],
    fetchViews: ['section'],
    fetchViewsSuccess: ['views'],
    createView: ['filters', 'name'],
    deleteView: ['id'],
    setViewsLoading: ['isloadingViews'],
    setViewAsSelected: ['id'],
    setViewAsSelectedSuccess: ['selectedView'],
    setSearch: ['search'],
    setSearchSuccess: ['search'],
    setPrevParams: ['prevParams'],
    updateParticipantsListAfterUpdate: [],
    resetParticipants: [],
  },
  { prefix: '@BE-PARTICIPANTS/' }
);

export const ParticipantsActions = Creators;
export const ParticipantsTypes = Types;

export const INITIAL_STATE = Immutable({
  participantsList: null,
  prevParams: '',
  isloadingParticipant: false,
  isloadingFilters: {
    [filtersKeys.status]: false,
    [filtersKeys.groups]: false,
    [filtersKeys.providers]: false,
    [filtersKeys.categories]: false,
    [filtersKeys.organizations]: false,
    [filtersKeys.has_active_case]: false,
  },
  filters: {
    [filtersKeys.status]: [],
    [filtersKeys.groups]: [],
    [filtersKeys.providers]: [],
    [filtersKeys.categories]: [],
    [filtersKeys.organizations]: [],
    [filtersKeys.has_active_case]: {
      id: 1,
      label: 'With Active Case',
      value: 'true',
    },
  },
  fields: {
    [filtersKeys.groups]: [],
    [filtersKeys.providers]: [],
  },
  isloadingFields: {
    [filtersKeys.groups]: false,
    [filtersKeys.providers]: false,
  },
  selectedFilters: {
    [filtersKeys.status]: null,
    [filtersKeys.groups]: [],
    [filtersKeys.providers]: [],
    [filtersKeys.categories]: [],
    [filtersKeys.organizations]: [],
    [filtersKeys.has_active_case]: {
      id: 1,
      label: 'With Active Case',
      value: 'true',
    },
  },
  navigation: {
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  },
  search: null,
  ordering: null,
  views: null,
  isloadingViews: false,
  selectedView: null,
  errors: [],
});

export const ParticipantsSelectors = {
  getParticipants: ({ participants }) => participants.participantsList,
  getParticipantsLoading: ({ participants }) =>
    participants.isloadingParticipant,
  getFiltersIsLoading: ({ participants }) => participants.isloadingFilters,
  getFieldsIsLoading: ({ participants }) => participants.isloadingFields,
  getFilters: ({ participants }) => participants.filters,
  getFields: ({ participants }) => participants.fields,
  getSelectedFilters: ({ participants }) => participants.selectedFilters,
  getNavigation: ({ participants }) => participants.navigation,
  getSearch: ({ participants }) => participants.search ?? '',
  getOrdering: ({ participants }) => participants.ordering,
  getViews: ({ participants }) => participants.views,
  getViewsLoading: ({ participants }) => participants.isloadingViews,
  getSelectedView: ({ participants }) => participants.selectedView,
  getErrors: ({ participants }) => participants.errors,
  getPrevParams: ({ participants }) => participants.prevParams,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchParticipantsSuccess = (state, { participantsList, prevParams }) =>
  state.merge({ participantsList, prevParams });

const setParticipantsLoading = (state, { isloadingParticipant }) =>
  state.merge({ isloadingParticipant });

const setNavigation = (state, { navigation }) =>
  state.merge({
    navigation: {
      ...state.navigation,
      ...navigation,
    },
  });

const setParticipantsErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
  });

const fetchFiltersSuccess = (state, { filters }) =>
  state.merge({
    filters: { ...state.filters, ...filters },
  });

const fetchFieldsSuccess = (state, { fields }) =>
  state.merge({
    fields: { ...state.fields, ...fields },
  });

const setFiltersLoading = (state, { isloadingFilters }) =>
  state.merge({
    isloadingFilters: { ...state.isloadingFilters, ...isloadingFilters },
  });

const setSelectedFiltersSuccess = (state, { filters, search }) =>
  state.merge({
    selectedFilters: { ...state.selectedFilters, ...filters },
    search: search ?? null,
  });

const setOrdering = (state, { ordering }) => state.merge({ ordering });

const fetchViewsSuccess = (state, { views }) => state.merge({ views });

const setViewsLoading = (state, { isloadingViews }) =>
  state.merge({ isloadingViews });

const setViewAsSelectedSuccess = (state, { selectedView }) =>
  state.merge({ selectedView });

const setPrevParams = (state, { prevParams }) => state.merge({ prevParams });

const setSearchSuccess = (state, { search }) => state.merge({ search });

const setFieldsLoading = (state, { isloadingFields }) =>
  state.merge({
    isloadingFields: { ...state.isloadingFields, ...isloadingFields },
  });

const resetParticipants = (state) => state.merge({ participantsList: null });

export const ParticipantsReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_PARTICIPANTS_SUCCESS]: fetchParticipantsSuccess,
  [Types.SET_PARTICIPANTS_LOADING]: setParticipantsLoading,
  [Types.SET_PARTICIPANTS_ERRORS]: setParticipantsErrors,
  [Types.FETCH_FILTERS_SUCCESS]: fetchFiltersSuccess,
  [Types.SET_FILTERS_LOADING]: setFiltersLoading,
  [Types.RESET_PARTICIPANTS]: resetParticipants,
  [Types.SET_SELECTED_FILTERS_SUCCESS]: setSelectedFiltersSuccess,
  [Types.SET_NAVIGATION]: setNavigation,
  [Types.SET_ORDERING]: setOrdering,
  [Types.FETCH_VIEWS_SUCCESS]: fetchViewsSuccess,
  [Types.SET_VIEWS_LOADING]: setViewsLoading,
  [Types.SET_VIEW_AS_SELECTED_SUCCESS]: setViewAsSelectedSuccess,
  [Types.SET_SEARCH_SUCCESS]: setSearchSuccess,
  [Types.SET_PREV_PARAMS]: setPrevParams,
  [Types.FETCH_FIELDS_SUCCESS]: fetchFieldsSuccess,
  [Types.SET_FIELDS_LOADING]: setFieldsLoading,
});
