import { getRequest, postRequest, putRequest } from './base';

export const getPrograms = (search = '', category, region) => {
  return getRequest(`programs/getall?search=${search || ''}`).then(
    (response) => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    }
  );
};

export const getProgramSessions = async ({
  startDate,
  endDate,
  programIds = [],
  participantId,
}) => {
  const path = '/participant/sessions';

  const endDateQuery = endDate ? `&endDate=${endDate}` : '';
  const programIdsQuery = programIds.length
    ? `&programIds=${programIds.join(',')}`
    : '';
  const participantQuery = participantId
    ? `&participantId=${participantId}`
    : '';
  const queryParams = `?startDate=${startDate}${endDateQuery}${programIdsQuery}${participantQuery}`;

  return getRequest(path + queryParams);
};

export const getProgramsFilter = (
  search = '',
  category = null,
  region = null
) => {
  return getRequest(
    `programs/getall?search=${search || ''}&category=${category || ''}&region=${
      region || ''
    }`
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getProgramById = (program_id = '', getExtraData, editable) => {
  return getRequest(
    `programs/getbyid/${program_id || ''}${getExtraData ? '?extra=true' : ''}${
      editable ? '?editable=true' : ''
    }`
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getProgramsByParticipantId = (participant_id = '') => {
  return getRequest(`programs/getbyuser?user_id=${participant_id || ''}`).then(
    (response) => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    }
  );
};

export const getProgramsExtended = (participantId, caseId, signal) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  return getRequest(
    `programs/getcomplete/${participantId}?case_id=${caseId}`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getPresignedPostUrl = (fileName) => {
  return postRequest('import/presigned', { file_name: fileName }).then(
    (response) => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    }
  );
};

export const getExportProgram = (body) => {
  return postRequest('programs/export', body).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getPrintProgram = (body) => {
  const requestBody = { ...body };
  requestBody.timezone = new Date().getTimezoneOffset();
  return postRequest('print', requestBody).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getCategoryAndRegionFilters = () => {
  return getRequest('programs/filters').then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getTemplates = (search = '') => {
  return getRequest(`templates?search=${search || ''}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const updateProgram = (id, body) => {
  return putRequest(`programs/${id}`, body).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getExerciseGroupFromExercise = (exerciseId) => {
  return getRequest(`/exercisegroupbyexercise/${exerciseId}`).then(
    (response) => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    }
  );
};

export const createCustomExercise = async (data) => {
  const response = await postRequest(`/exercises/custom`, data);

  return response.data;
};

export const createHepProgram = async (data) => {
  const response = await postRequest('/programs', data);

  return response.data;
};
