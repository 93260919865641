import moment from 'moment';
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchParticipant: ['participantId'],
    fetchParticipantSuccess: ['data'],
    refreshParticipant: ['participantId'],
    setRefreshParticipant: ['isRefreshing'],
    fetchPrograms: ['participantId'],
    fetchProgramsSuccess: ['programs'],
    fetchAssessments: ['participantId'],
    fetchAssessmentsSuccess: ['assessments', 'nextSchedule'],
    fetchAssessmentAnswers: ['participantId'],
    fetchAssessmentAnswersSuccess: ['answers'],
    fetchNotes: ['participantId'],
    fetchNotesSuccess: ['notes', 'timeTrackerNotes'],
    fetchProgress: ['participantId', 'date'],
    fetchProgressSuccess: ['progress'],
    setCurrentDate: ['participantId', 'date'],
    setCurrentDateSuccess: ['progressDates'],
    setNotes: ['note'],
    setNotesSuccess: ['addNoteOk'],
    setCurrentProgram: ['currentProgram'],
    setCurrentLevel: ['currentLevel'],
    setPreviousLevel: [],
    setAssignLevel: ['level', 'phase'],
    fetchFiles: ['participantId'],
    fetchFilesSuccess: ['files'],
    setFile: ['participantId', 'file'],
    setParticipantLoading: ['isloadingParticipant'],
    setProgramLoading: ['isloadingProgram'],
    setPrintProgramLoading: ['isloadingPrintProgram'],
    setAssingLevelLoading: ['isloadingAssingLevel'],
    setAssingLevelOk: ['assingLevelOk'],
    setAssessmentsLoading: ['isloadingAssessments'],
    setAssessmentAnswersLoading: ['isloadingAssessmentAnswers'],
    setNotesLoading: ['isLoadingNotes'],
    setNotesModalLoading: ['isNotesModalLoading'],
    setFilesLoading: ['isFilesLoading'],
    setProgressLoading: ['isProgressLoading'],
    fetchAssessmentSchedule: ['participantId'],
    fetchAssessmentScheduleSuccess: ['assessmentSchedule'],
    setAssessmentScheduleLoading: ['isLoadingAssessmentSchedule'],
    setManualAssessment: ['participantId'],
    setManualAssessmentLoading: ['manualAssessmentLoading'],
    setParticipantErrors: ['error'],
    setCanHaveManualAssessment: ['canHaveManualAssessment'],
    setNoteHistorySelected: ['noteHistorySelected'],
    setSelectedCase: ['caseId'],
    setSelectedCaseSuccess: ['caseSelected'],
    setRedirectToError: ['redirectToError'],
    resetParticipant: [],
  },
  { prefix: '@BE-PARTICIPANT/' }
);

export const ParticipantActions = Creators;
export const ParticipantTypes = Types;

export const INITIAL_STATE = Immutable({
  data: null,
  programs: null,
  progress: null,
  progressDates: {
    dates: [],
    currentDate: moment().format('MM-DD-YYYY'),
  },
  printProgram: null,
  prevLevel: {
    level: null,
    phase: null,
  },
  currentLevel: {
    level: null,
    phase: null,
  },
  currentProgram: null,
  assessments: null,
  nextSchedule: null,
  notes: {
    notes: [],
    timeTrackerNotes: [],
  },
  cases: [],
  noteHistorySelected: null,
  caseSelected: null,
  files: null,
  assessmentSchedule: [],
  isLoadingAssessmentSchedule: false,
  isloadingParticipant: false,
  isloadingProgram: false,
  isloadingPrintProgram: false,
  isloadingAssingLevel: false,
  assingLevelOk: null,
  isloadingAssessments: false,
  isloadingAssessmentAnswers: false,
  isNotesModalLoading: false,
  isLoadingNotes: false,
  addNoteOk: null,
  isFilesLoading: false,
  isProgressLoading: false,
  isRefreshing: false,
  manualAssessmentLoading: false,
  canHaveManualAssessment: false,
  redirectToError: false,
  errors: [],
});

export const ParticipantSelectors = {
  getParticipant: ({ participant }) => participant.data,
  getParticipantLoading: ({ participant }) => participant.isloadingParticipant,
  getParticipantRefreshing: ({ participant }) => participant.isRefreshing,
  getPrograms: ({ participant }) => participant.programs,
  getCurrentProgram: ({ participant }) => participant.currentProgram,
  getProgramLoading: ({ participant }) => participant.isloadingProgram,
  getProgramPrintLoading: ({ participant }) =>
    participant.isloadingPrintProgram,
  getAssingLevelLoading: ({ participant }) => participant.isloadingAssingLevel,
  getCurrentLevel: ({ participant }) => participant.currentLevel,
  getAssessments: ({ participant }) => participant.assessments,
  getAssessmentsLoading: ({ participant }) => participant.isloadingAssessments,
  getAssessmentAnswers: ({ participant }) => participant.answers,
  getAssesmenttAnswersLoading: ({ participant }) =>
    participant.isloadingAssessmentAnswers,
  getNonParticipantErrors: ({ participant }) =>
    participant.errors.find((e) => e?.notParticipant),
  getNotes: ({ participant }) => participant.notes,
  getNotesLoading: ({ participant }) => participant.isLoadingNotes,
  getAddNoteOk: ({ participant }) => participant.addNoteOk,
  getNotesModalLoading: ({ participant }) => participant.isNotesModalLoading,
  getFiles: ({ participant }) => participant.files,
  getFilesLoading: ({ participant }) => participant.isFilesLoading,
  getProgress: ({ participant }) => participant.progress,
  getProgressLoading: ({ participant }) => participant.isProgressLoading,
  getProgressDates: ({ participant }) => participant.progressDates,
  getAssingLevelOk: ({ participant }) => participant.assingLevelOk,
  getAssessmentSchedule: ({ participant }) => participant.assessmentSchedule,
  getAssessmentScheduleLoading: ({ participant }) =>
    participant.isLoadingAssessmentSchedule,
  getManualAssessmentLoading: ({ participant }) =>
    participant.manualAssessmentLoading,
  getCanHaveManualAssessment: ({ participant }) =>
    participant.canHaveManualAssessment,
  getNextSchedule: ({ participant }) => participant.nextSchedule,
  getNoteHistorySelected: ({ participant }) => participant.noteHistorySelected,
  getCaseSelected: ({ participant }) => participant.caseSelected,
  getRedirectToError: ({ participant }) => participant.redirectToError,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchParticipantSuccess = (state, { data }) => state.merge({ data });

const setRefreshParticipant = (state, { isRefreshing }) =>
  state.merge({ isRefreshing });

const fetchProgramsSuccess = (state, { programs }) => state.merge({ programs });

const fetchProgressSuccess = (state, { progress }) => state.merge({ progress });

const setCurrentLevel = (state, { currentLevel }) =>
  state.merge({
    prevLevel: {
      level: state.currentLevel.level,
      phase: state.currentLevel.phase,
    },
    currentLevel: {
      level: currentLevel?.level,
      phase: currentLevel?.phase,
    },
  });

const setPreviousLevel = (state) =>
  state.merge({
    prevLevel: {
      level: null,
      phase: null,
    },
    currentLevel: {
      ...state.prevLevel,
    },
  });

const setAssingLevelOk = (state, { assingLevelOk }) =>
  state.merge({ assingLevelOk });

const setCurrentProgram = (state, { currentProgram }) =>
  state.merge({ currentProgram });

const setCurrentDateSuccess = (state, { progressDates }) =>
  state.merge({ progressDates });

const fetchAssessmentsSuccess = (state, { assessments, nextSchedule }) =>
  state.merge({ assessments, nextSchedule });

const fetchAssessmentAnswersSuccess = (state, { answers }) =>
  state.merge({ answers });

const fetchNotesSuccess = (state, { notes }) => state.merge({ notes });

const setNotesSuccess = (state, { addNoteOk }) => state.merge({ addNoteOk });

const fetchFilesSuccess = (state, { files }) => state.merge({ files });

const setParticipantLoading = (state, { isloadingParticipant }) =>
  state.merge({ isloadingParticipant });

const setProgramLoading = (state, { isloadingProgram }) =>
  state.merge({ isloadingProgram });

const setPrintProgramLoading = (state, { isloadingPrintProgram }) =>
  state.merge({ isloadingPrintProgram });

const setAssingLevelLoading = (state, { isloadingAssingLevel }) =>
  state.merge({ isloadingAssingLevel });

const setAssessmentsLoading = (state, { isloadingAssessments }) =>
  state.merge({ isloadingAssessments });

const setNotesModalLoading = (state, { isNotesModalLoading }) =>
  state.merge({ isNotesModalLoading });

const setNotesLoading = (state, { isLoadingNotes }) =>
  state.merge({ isLoadingNotes });

const setFilesLoading = (state, { isFilesLoading }) =>
  state.merge({ isFilesLoading });

const setProgressLoading = (state, { isProgressLoading }) =>
  state.merge({ isProgressLoading });

const setParticipantErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
  });

const fetchAssessmentScheduleSuccess = (state, { assessmentSchedule }) =>
  state.merge({ assessmentSchedule });

const setAssessmentScheduleLoading = (state, { isLoadingAssessmentSchedule }) =>
  state.merge({ isLoadingAssessmentSchedule });

const setManualAssessmentLoading = (state, { manualAssessmentLoading }) =>
  state.merge({ manualAssessmentLoading });

const setCanHaveManualAssessment = (state, { canHaveManualAssessment }) =>
  state.merge({ canHaveManualAssessment });

const setNoteHistorySelected = (state, { noteHistorySelected }) =>
  state.merge({ noteHistorySelected });

const setSelectedCaseSuccess = (state, { caseSelected }) =>
  state.merge({ caseSelected });

const setRedirectToError = (state, { redirectToError }) =>
  state.merge({ redirectToError });

const resetParticipant = (state) => state.merge({ data: null });

export const ParticipantReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_PARTICIPANT_SUCCESS]: fetchParticipantSuccess,
  [Types.SET_REFRESH_PARTICIPANT]: setRefreshParticipant,
  [Types.FETCH_PROGRAMS_SUCCESS]: fetchProgramsSuccess,
  [Types.FETCH_ASSESSMENTS_SUCCESS]: fetchAssessmentsSuccess,
  [Types.FETCH_ASSESSMENT_ANSWERS_SUCCESS]: fetchAssessmentAnswersSuccess,
  [Types.FETCH_NOTES_SUCCESS]: fetchNotesSuccess,
  [Types.SET_NOTES_SUCCESS]: setNotesSuccess,
  [Types.FETCH_FILES_SUCCESS]: fetchFilesSuccess,
  [Types.FETCH_PROGRESS_SUCCESS]: fetchProgressSuccess,
  [Types.SET_CURRENT_DATE_SUCCESS]: setCurrentDateSuccess,
  [Types.SET_CURRENT_LEVEL]: setCurrentLevel,
  [Types.SET_PREVIOUS_LEVEL]: setPreviousLevel,
  [Types.SET_CURRENT_PROGRAM]: setCurrentProgram,
  [Types.SET_PARTICIPANT_LOADING]: setParticipantLoading,
  [Types.SET_PROGRAM_LOADING]: setProgramLoading,
  [Types.SET_ASSING_LEVEL_LOADING]: setAssingLevelLoading,
  [Types.SET_PRINT_PROGRAM_LOADING]: setPrintProgramLoading,
  [Types.SET_ASSESSMENTS_LOADING]: setAssessmentsLoading,
  [Types.SET_PARTICIPANT_ERRORS]: setParticipantErrors,
  [Types.SET_NOTES_LOADING]: setNotesLoading,
  [Types.SET_NOTES_MODAL_LOADING]: setNotesModalLoading,
  [Types.SET_FILES_LOADING]: setFilesLoading,
  [Types.SET_PROGRESS_LOADING]: setProgressLoading,
  [Types.SET_ASSING_LEVEL_OK]: setAssingLevelOk,
  [Types.FETCH_ASSESSMENT_SCHEDULE_SUCCESS]: fetchAssessmentScheduleSuccess,
  [Types.SET_ASSESSMENT_SCHEDULE_LOADING]: setAssessmentScheduleLoading,
  [Types.SET_MANUAL_ASSESSMENT_LOADING]: setManualAssessmentLoading,
  [Types.SET_CAN_HAVE_MANUAL_ASSESSMENT]: setCanHaveManualAssessment,
  [Types.SET_NOTE_HISTORY_SELECTED]: setNoteHistorySelected,
  [Types.SET_SELECTED_CASE_SUCCESS]: setSelectedCaseSuccess,
  [Types.RESET_PARTICIPANT]: resetParticipant,
  [Types.SET_REDIRECT_TO_ERROR]: setRedirectToError,
});
